<template src="./template.html"></template>

<script>
import EditEffect from "@/components/Effect/EditDialog/index"
import ViewEffect from "@/components/Effect/ViewDialog/index"
export default {
  name: "EffectPage",
  components: {
    EditEffect,
    ViewEffect
  },
  data() {
    return {
      headers: [
        {
          text: '檢視',
          align: 'start',
          value: 'View',
          width: 80
        },
        {
          text: '填寫狀況',
          align: 'start',
          value: 'ProjectNo',
        },
        {
          text: '客戶名稱',
          align: 'start',
          value: 'CustomerID',
        },
        {
          text: '案件標題',
          align: 'start',
          value: 'Name',
        },
        {
          text: '投放項目',
          align: 'start',
          value: 'ProjectType',
        },
        { text: '結束日期', value: 'EndDate' },
        { text: '動作', value: 'actions', sortable: false },
      ],
      footerprops: {
        'items-per-page-text': '每頁顯示',
      },
      status_array: [
        {
          key: "All",
          value: "全部狀態"
        },
        {
          key: "W",
          value: "未審核"
        },
        {
          key: "P",
          value: "尚未開始"
        },
        {
          key: "D",
          value: "進行中"
        },
        {
          key: "E",
          value: "結束"
        },
        {
          key: "C",
          value: "未通過"
        },
      ],
      project: [],
      customers: [],
      active_status: "All",
      active_customer: "All",
      date: ["", ""],
      menu: false,
      modal: false,
      date_filter_enable: true
    }
  },

  created() {
    let date = new Date().toISOString().substr(0, 7)
    let now_year = parseInt(date.substr(0, 4))
    let now_month = parseInt(date.substr(5, 2))
    this.date[0] = new Date(now_year, now_month - 1, 1).Format("yyyy-MM-dd")
    this.date[1] = new Date(now_year, now_month, 0).Format("yyyy-MM-dd")
    this.LoadAdminData()
  },

  methods: {
    OpenEdit(item) {
      this.$refs.EditEffect.Open(item)
    },
    OpenView(item) {
      this.$refs.ViewEffect.Open(item)
    },
    ChangeDate(date) {
      if (new Date(date[0]) > new Date(date[1])) {
        date = date.reverse()
      }
      this.$refs.menu.save(date)
    },
    ProjectTypeColor(type) {
      return this.$store.state.project_type_color[type]
    },
    ProjectTypeFilter(type) {
      if (type == null) {
        return ""
      }
      let now_type = Object.keys(this.project_type).filter(item => item == type)
      return (now_type.length > 0) ? now_type[0] : ""
    },
    StatusProjectsFilter(projects) {
      if (this.active_status == "All") {
        return projects
      }
      else {
        return projects.filter(item => item.Status == this.active_status)
      }
    },
    CustomerFilter(customer) {
      let now_customer = this.customers.filter(item => parseInt(item.CustomerID) == parseInt(customer))
      return (now_customer.length > 0) ? now_customer[0].Name : ""
    },
    StatusFilter(status) {
      return this.status_array[status];
    },
    async LoadCustomerData() {
      this.loading = true
      let response = await this.GetData("/admin/customer")
      this.loading = false
      if (response != "error") {

        let Data = response
        Data.forEach(item => {
          Object.keys(this.customer_auth).forEach(customer => {
            item.CustomerID == customer ? item.Admin = this.customer_auth[customer] : ""
          })
        });
        this.customers = []
        this.customers.push({ Name: "全部客戶", CustomerID: "All" })
        if (this.account().role == "C") {
          Data.forEach(item => {
            if (item.Admin.filter(account => account == this.account().id).length > 0) {
              this.customers.push(item)
            }
          })
        }
        else {
          this.customers = this.customers.concat(Data)
        }


        // this.customers = []
        // this.customers = this.customers.concat(response)
        this.active_customer = this.customers[0].CustomerID

        this.LoadData()
      }
    },
    async LoadData() {
      this.loading = true
      let response = await this.GetData("/admin/project")
      this.loading = false
      if (response != "error") {
        this.project = []
        this.project = response.filter(item => {
          let end_date = new Date(item.EndDate)
          let now_date = new Date()

          return this.customers.filter(customer => customer.CustomerID == item.CustomerID).length > 0 && now_date > end_date
        })
        console.log(this.project)
      }
    },
    async LoadAdminData() {
      this.loading = true
      let response = await this.SendData("/admin/customer/auth/get", "post", { Data: {} })
      this.loading = false
      if (response != "error") {

        this.customer_auth = response
        this.LoadCustomerData()
      }
    },
    account() {
      if (this.$cookie.get("account") == null) {
        this.$store.commit("SetSnackBar", {
          status: true,
          content: "帳號驗證錯誤，請重新登入"
        })
        this.$cookie.delete('account');
        this.$cookie.delete('user_token');
        this.$router.push("/login")
        return { name: "", role: "" }
      }
      else {
        return JSON.parse(this.$cookie.get("account"))
      }
    },
    GetEditStatus(item) {
      let string = parseInt(item.CPA) + parseInt(item.Click) + parseInt(item.Impression) + parseInt(item.Reach) + parseInt(item.Talking) + parseInt(item.Engagement)
      return string <= 0 && item.RemarkResult == "" ? "尚未填寫" : "已填寫";
    }
  },
  computed: {
    project_type() {
      return this.$store.state.project_type
    },
    dateRangeText() {
      return this.date.join(' ~ ')
    },

    filter_projects() {
      if (this.active_customer == "All") {
        console.log(this.project.filter(item => item.Name == "testt"))
        return this.project.filter(item => {
          if (this.date_filter_enable) {
            if (new Date(item.EndDate) >= new Date(this.date[0]) && new Date(item.EndDate) <= new Date(this.date[1])) {
              return item
            }
          }
          else {
            return item
          }
        }).sort((a, b) => {
          return new Date(b.EndDate) - new Date(a.EndDate)
        })
      } else {
        return this.project.filter(item => {
          if (this.date_filter_enable) {

            if (item.CustomerID == this.active_customer && new Date(item.EndDate) >= new Date(this.date[0]) && new Date(item.EndDate) <= new Date(this.date[1])) {
              return item
            }

          }
          else {
            return item.CustomerID == this.active_customer
          }
        }).sort((a, b) => {
          return new Date(b.EndDate) - new Date(a.EndDate)
        })
      }
    },
  },

  filters: {
    word_break(val) {
      if (val.length <= 12) {
        return val
      }
      else {
        return val.substring(0, 12) + "..."
      }
    },
  },
}
</script>