var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-md-10 pa-sm-1 pa-3",attrs:{"id":"Projects"}},[_c('EditEffect',{ref:"EditEffect",attrs:{"customers":_vm.customers},on:{"load-data":_vm.LoadData}}),_c('ViewEffect',{ref:"ViewEffect",attrs:{"customers":_vm.customers},on:{"load-data":_vm.LoadData}}),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"pt-10",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.customers,"item-text":"Name","item-value":"CustomerID","label":"案件客戶"},model:{value:(_vm.active_customer),callback:function ($$v) {_vm.active_customer=$$v},expression:"active_customer"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.date_filter_enable==true),expression:"date_filter_enable==true"}],staticClass:"pt-10",attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"日期區間","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":"","locale":"zh-tw"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.ChangeDate(_vm.date)}}},[_vm._v(" 確認 ")])],1)],1)],1),_c('v-switch',{staticClass:"pt-10 pr-5",attrs:{"label":"日期篩選"},model:{value:(_vm.date_filter_enable),callback:function ($$v) {_vm.date_filter_enable=$$v},expression:"date_filter_enable"}})],1),_c('v-card',{attrs:{"color":"#fff"}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"footer-props":_vm.footerprops,"items":_vm.filter_projects,"sort-by":"calories"},scopedSlots:_vm._u([{key:"item.View",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.OpenView(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.ProjectNo",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'green--text text--darken-3':_vm.GetEditStatus(item)=='已填寫','red--text text--darken-3':_vm.GetEditStatus(item)=='尚未填寫'}},[_vm._v(" "+_vm._s(_vm.GetEditStatus(item))+" ")])]}},{key:"item.CustomerID",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.CustomerFilter(item.CustomerID))+" ")]}},{key:"item.ProjectType",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0",class:_vm.ProjectTypeColor(item.Type1)},[_vm._v(_vm._s(_vm.ProjectTypeFilter(item.Type1))+" ")])]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("word_break")(item.Name))+" ")]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.StatusFilter(item.Status))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.account().role=='A' || _vm.account().role=='E')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.OpenEdit(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("目前沒有資料")])]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }