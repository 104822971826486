<template src="./template.html"></template>

<script>
import { ExcelExport } from 'pikaz-excel-js'
export default {
  name: "EffectView",
  props: {
    customers: {
      require: true,
      type: Array
    }
  },
  components: {
    ExcelExport
  },
  data() {
    return {
      dialog: false,
      valid: true,
      project: null,
      Images: [],
      ShowImage: "",
      ImageBox: false,
      delete_image: [],
      status_array: {
        W: "未審核",
        P: "尚未開始",
        D: "進行中",
        E: "結束",
        C: "未通過"
      },
      rules: {
        require: value => {
          return (value == "") ? "此欄位不得留空" : true
        },
      },
      export_sheet: [
        {
          title: "",
          multiHeader: [["投放日期", "", "", "", "", ""], ['曝光人數', '貼文觸及人數/互動次數', '貼文互動次數', '開啟訊息對話', '連結點擊', '每次成果的成本']],
          merges: ['B2:F2'],
          table: [],
          keys: ['Impression', 'Reach', 'Engagement', 'Talking', 'Click', 'CPA'],
          sheetName: '',
        }
      ]
    }
  },
  methods: {
    Open(item) {
      this.project = Object.assign({}, item)
      this.LoadData()
      this.valid = true
      this.dialog = true
    },
    Close() {
      this.project = null
      this.dialog = false
    },
    OpenImageBox(image) {
      this.ImageBox = true
      this.ShowImage = image
    },
    CloseImageBox() {
      this.ImageBox = false
      this.ShowImage = ""
    },
    GetCustomer(id) {
      let now_customer = this.customers.filter(item => item.CustomerID == id)
      return (now_customer.length > 0) ? now_customer[0].Name : ""
    },
    GetEndDate(date, day) {
      let now_date = new Date(date);
      return now_date.addDays(day).Format("yyyy-MM-dd");
    },
    StatusColorFilter(status) {
      let colors = {
        W: "blue-grey lighten-2 white--text",
        P: "blue-grey darken-4 white--text",
        D: "green darken-3 white--text",
        E: "deep-orange darken-4 white--text",
        C: "amber accent-4 white--text"
      }
      return colors[status]
    },
    ImageDownload() {
      console.log(this.Images)
      for (let item of this.Images) {
        const image = new Image()
        image.setAttribute('crossOrigin', 'anonymous')
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const context = canvas.getContext('2d')
          context.drawImage(image, 0, 0, image.width, image.height)
          canvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.download = item.Image.split("/")[2].split(".")[0]
            a.href = url
            a.click()
            a.remove()
            URL.revokeObjectURL(url)
          })
        }
        image.src = "https://ad.yongxin-design.com/api/portal" + item.Image;
      }
    },
    async LoadData() {
      this.$store.commit("SetLoading", true)
      let response = await this.GetData("/admin/project/image/" + this.project.ProjectID)
      this.$store.commit("SetLoading", false)
      if (response != "error") {

        this.Images = response
        this.Images.length > 0 ? this.ShowImage = this.Images[0].Image : ""
      }
    },
    CreateExportTable() {
      this.export_sheet[0].title = this.GetCustomer(this.project.CustomerID) + " - " + this.project.Name
      this.export_sheet[0].sheetName = this.project.Name
      this.export_sheet[0].multiHeader[0][1] = this.project.StartDate + " 到 " + this.GetEndDate(this.project.StartDate, this.project.Day)
      this.export_sheet[0].table = [{
        Impression: this.project.Impression,
        Reach: this.project.Reach,
        Engagement: this.project.Engagement,
        Talking: this.project.Talking,
        Click: this.project.Click,
        CPA: this.project.CPA
      }]
      this.$refs.export_btn.click()
    },
  },
  filters: {
    currency(price) {
      let new_price = Math.abs(parseInt(price)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

      return (parseInt(price) < 0) ? "- $" + new_price.slice(0, new_price.length - 3) : " $" + new_price.slice(0, new_price.length - 3)
    },
  }

}
</script>