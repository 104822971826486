<template src="./template.html"></template>

<script>
export default {
  name: "EffectEdit",
  props: {
    customers: {
      require: true,
      type: Array
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      project: null,
      Images: [],
      delete_image: [],
      rules: {
        require: value => {
          return (value == "") ? "此欄位不得留空" : true
        },
      },
    }
  },
  methods: {
    Open(item) {
      this.project = Object.assign({}, item)
      this.LoadData()
      this.valid = true
      this.dialog = true
    },
    Close() {
      this.project = null
      this.Images = []
      this.delete_image = []
      this.dialog = false
    },
    GetCustomer(id) {
      return this.customers.filter(item => item.CustomerID == id)[0].Name
    },
    GetEndDate(date, day) {
      let now_date = new Date(date);
      return now_date.addDays(day).Format("yyyy-MM-dd");
    },
    CreateImage() {
      this.$refs.new_image_input.click()
    },
    DeleteImage(item_index) {
      if (this.Images[item_index].ProjectID) {
        let img = Object.assign({}, this.Images[item_index])
        this.delete_image.push(img)
      }
      this.Images.splice(item_index, 1)
    },
    async ChangeNewImage(val) {
      console.log(val)
      if (val.target.files.length > 0) {
        for (let item of val.target.files) {
          this.Images.push(await this.GetArrayBuffer(item))
        }
        val.target.value = ""
      }
      // val.target.files.length > 0 ? this.Images.push(await this.GetArrayBuffer(val.target.files[0])) : ""
      // val.target.value = ""
    },


    Validate() {
      this.$refs.ProductForm.validate()


      setTimeout(() => {
        this.valid == true ? this.ConvertData() : ""
      }, 100);
    },
    async ConvertData() {
      let CreateImageArray = []

      this.Images.forEach((item) => {
        item.ProjectID ? "" : CreateImageArray.push({ ProjectID: parseInt(this.project.ProjectID), Image: item });
      });

      await this.SendCreateImage(CreateImageArray)
      await this.SendDeleteImage()
      this.UpdateData()
    },
    async SendCreateImage(Data) {
      this.$store.commit("SetLoading", true)
      for (let item of Data) {
        await this.SendData("/admin/project/image", "post", { Data: item })
      }
      this.$store.commit("SetLoading", false)
    },
    async SendDeleteImage() {
      this.$store.commit("SetLoading", true)
      for (let item of this.delete_image) {
        await this.SendData("/admin/project/image", "delete", { Data: { ProjectImageID: item.ProjectImageID } })
      }
      this.$store.commit("SetLoading", false)
    },
    async UpdateData() {
      let Data = {}
      Data.ProjectID = parseInt(this.project.ProjectID)
      Data.Impression = parseInt(this.project.Impression)
      Data.Reach = parseInt(this.project.Reach)
      Data.Engagement = parseInt(this.project.Engagement)
      Data.Talking = parseInt(this.project.Talking)
      Data.Click = parseInt(this.project.Click)
      Data.CPA = parseInt(this.project.CPA)
      Data.RemarkResult = this.project.RemarkResult
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/project/number", "patch", { Data: Data })
      this.$store.commit("SetLoading", false)
      if (response != "error") {
        this.Close()
        this.$emit("load-data")
        this.$store.commit("SetSnackBar", { status: true, content: "已成功更新廣告成效" })
      }
    },

    async LoadData() {
      this.$store.commit("SetLoading", true)
      let response = await this.GetData("/admin/project/image/" + this.project.ProjectID)
      this.$store.commit("SetLoading", false)
      if (response != "error") {

        this.Images = response
      }
    }
  },
  mounted() {

  }

}
</script>